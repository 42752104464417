import { graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import Breadcrumbs from "../components/Breadcrumbs";
import ContactForm from "../components/Contactformulier";
import Layout from "../components/layout";
import RelatedCases from "../components/RelatedCases";
import SEO from "../components/seo";

export const ServicePageTemplate = ({ post, breadcrumbs }) => {
  return (
    <div className="container mx-auto px-6 text-white flex flex-col  ">
      <div className="max-w-4xl mb-12 ">
        <Breadcrumbs breadcrumbs={breadcrumbs} />
        <p className="mt-6 leading-relaxed sm:text-lg md:text-2xl xl:text-2xl text-white">
          {post.lead_paragraph}
        </p>
        <ReactMarkdown
          className="text-lg mb-2 font-light pt-6 netlify-html text-white"
          source={
            post.description
              ? post.description.split(`](assets`).join(`](/assets`)
              : ""
          }
        />
      </div>

      <div className="mt-4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4">
        {post.service_item_categories && post.service_item_categories.length > 0
          ? post.service_item_categories.map((item) => {
              if (!item) {
                return undefined;
              }
              return (
                <div
                  className="flex flex-col  items-center justify-center w-full"
                  key={item.id}
                >
                  <div className="flex flex-col  h-full w-full">
                    <div className="flex flex-col flex-grow">
                      {item.background && (
                        <BackgroundImage
                          aria-label="Fullscreen Background"
                          className=" w-full h-48 flex flex-col justify-center content-center items-center mb-3"
                          fluid={item.background.childImageSharp.fluid}
                          style={{
                            backgroundBlendMode: "multiply",
                            backgroundPosition: "center",
                          }}
                          Tag="section"
                        >
                          <>
                            {item.thumbnail &&
                              item.thumbnail.childImageSharp && (
                                <Img
                                  alt={item.id}
                                  fixed={item.thumbnail.childImageSharp.fixed}
                                  title={item.id}
                                />
                              )}
                            <h3 className="text-lg text-white font-bold mb-3 font-display ">
                              {item.id}
                            </h3>
                          </>
                        </BackgroundImage>
                      )}

                      <p className="text-base mb-2 font-light  ">
                        {item.caption}
                      </p>
                    </div>

                    <div className=" flex-grow-0 text-white flex flex-col justify-start content-start items-start text-center mt-2 mb-8 ">
                      <Link
                        className="bg-transparent hover:bg-gladior-pink  text-white  font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
                        to={item.fields.slug}
                      >
                        Meer informatie
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })
          : post.service_items &&
            post.service_items.map((item) => {
              if (!item) {
                return undefined;
              }
              return (
                <div
                  className="flex flex-col  items-center justify-center w-full"
                  key={item.id}
                >
                  <div className="flex flex-col  h-full w-full">
                    <div className="flex flex-col flex-grow">
                      <h3
                        className="text-lg text-white font-bold mb-3 font-display "
                        href={item.fields.slug}
                      >
                        {item.id}
                      </h3>
                      <div className="bg-gladior-dark w-full h-48 flex justify-center content-center items-center mb-3">
                        {item.thumbnail && item.thumbnail.childImageSharp && (
                          <Img
                            alt={item.id}
                            fixed={item.thumbnail.childImageSharp.fixed}
                            title={item.id}
                          />
                        )}

                        {item.background && item.background.childImageSharp && (
                          <Img
                            alt={item.id}
                            fixed={item.background.childImageSharp.fixed}
                            title={item.id}
                          />
                        )}
                      </div>
                      <p className="text-base mb-2 font-light  ">
                        {item.caption}
                      </p>
                    </div>

                    <div className=" flex-grow-0 text-white flex flex-col justify-start content-start items-start text-center mt-2 mb-8 ">
                      <Link
                        className="bg-transparent hover:bg-gladior-pink  text-gladior-pink  font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
                        to={item.fields.slug}
                      >
                        Meer informatie
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
      </div>

      {post.related_cases && post.related_cases.length > 0 && (
        <>
          <h3 className="mt-20 mb-10 text-2xl sm:text-3xl md:text-3xl xl:text-3xl leading-tight  text-white font-bold mb-3 font-display ">
            Our related cases
          </h3>
          <RelatedCases relatedCases={post.related_cases} />
        </>
      )}
    </div>
  );
};

const ServicePage = ({ data }) => {
  const { serviceYaml: post, allServiceCategoryYaml } = data;

  let breadcrumbs = [];

  breadcrumbs.push({
    name: "Services",
    slug: "/services/",
  });

  if (allServiceCategoryYaml && allServiceCategoryYaml.edges.length > 0) {
    let node = allServiceCategoryYaml.edges[0].node;
    breadcrumbs.push({
      name: node.id,
      slug: node.fields.slug,
    });
  }

  breadcrumbs.push({
    name: post.id,
  });

  return (
    <>
      <SEO
        description={post.seo.meta_description}
        pathname={post.path}
        title={post.seo.meta_title}
      />
      <Layout
        heroBackgroundImage={post.hero_background_image}
        title={post.title}
      >
        <main className="bg-gladior-dark ">
          <section className="container mx-auto px-6 py-10  max-w-7xl ">
            <ServicePageTemplate breadcrumbs={breadcrumbs} post={post} />
          </section>
          <ContactForm />
        </main>
      </Layout>
    </>
  );
};

ServicePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServicePage;

export const servicePageQuery = graphql`
  query ServicePage($id: String!) {
    allServiceCategoryYaml(
      filter: { services: { elemMatch: { id: { eq: $id } } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
        }
      }
    }
    serviceYaml(id: { eq: $id }) {
      id
      lead_paragraph
      seo {
        meta_description
        meta_title
      }

      hero_background_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      related_cases {
        about_customer
        body
        description
        id
        lead_paragraph
        quote {
          organisation
          person
          quote
        }
        seo {
          meta_description
          meta_title
        }
        services
        subtitle
        templateKey
        title
        fields {
          slug
        }
        cover_image {
          childImageSharp {
            fixed(height: 256) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
      thumbnail {
        childImageSharp {
          fixed(height: 100) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      title

      fields {
        slug
      }
      templateKey
      description
      service_item_categories {
        id
        thumbnail {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        background {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        caption
        title
        fields {
          slug
        }
      }
    }
  }
`;
