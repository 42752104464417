import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";

function Breadcrumbs({ breadcrumbs }) {
  const data = useStaticQuery(
    graphql`
      query {
        gladiorIcon: file(relativePath: { eq: "icon.png" }) {
          childImageSharp {
            fixed(height: 25) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `
  );

  return (
    <div className="flex flex-row items-center gladior-breadcrumbs-dark   invisible sm:invisible md:visible lg:visible xl:visible ">
      <Link className="flex flex-row items-center" to={"/"}>
        <Img
          className="shadow-xl hover:shadow-2xl gladior-icon opacity-75 hover:opacity-100"
          fixed={data.gladiorIcon.childImageSharp.fixed}
        />
      </Link>

      {breadcrumbs.map((breadcrumb) => {
        if (breadcrumb.slug) {
          return (
            <Link
              className="gladior-breadcrumbs-item text-sm hover:text-gladior-pink text-white  no-underline hover:underline"
              key={breadcrumb.name}
              to={breadcrumb.slug}
            >
              {breadcrumb.name}
            </Link>
          );
        } else {
          return (
            <p
              className="gladior-breadcrumbs-item text-sm  text-white"
              key={breadcrumb.name}
            >
              {breadcrumb.name}
            </p>
          );
        }
      })}
    </div>
  );
}

export default Breadcrumbs;
