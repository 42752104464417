import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";

function NextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        display: "block",
        padding: "0",
        right: "-55px",
        transform: "translateY(-50%)",
      }}
      className="h-12 w-12"
      onClick={onClick}
    >
      <button className="bg-white rounded-full shadow-md h-12 w-12 text-2xl text-gladior-black hover:text-gladior-pink focus:text-gladior-pink -mr-6 focus:outline-none focus:ring">
        <span className="block" style={{ transform: "scale(1)" }}>
          ➜
        </span>
      </button>
    </div>
  );
}

function PrevArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      style={{
        ...style,
        display: "block",
        position: "absolute",
        top: "50%",
        display: "block",
        padding: "0",
        left: "-55px",
        transform: "translateY(-50%)",
      }}
      className="h-12 w-12"
      onClick={onClick}
    >
      <button className="bg-white rounded-full shadow-md h-12 w-12 text-2xl text-gladior-black hover:text-gladior-pink focus:text-gladior-pink -mr-6 focus:outline-none focus:ring">
        <span className="block" style={{ transform: "scale(-1)" }}>
          ➜
        </span>
      </button>
    </div>
  );
}

function RelatedCases({ relatedCases }) {
  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,

    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          nextArrow: <NextArrow />,
          prevArrow: <PrevArrow />,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
        },
      },
    ],
  };

  return (
    <>
      <div
        id="coverbar"
        className="px-2 pb-10 w-full flex justify-between flex-wrap"
      >
        {relatedCases.map((coverObj) => {
          return (
            <div key={coverObj.id}>
              <div className="grid__link   flex flex-col items-center mb-8">
                <div className=" img-wrap img-wrap--grid h-64 w-64 mb-4  bg-white">
                  <div className="relative w-full h-full bg-white">
                    <Img
                      style={{ height: "256px", width: "256px" }}
                      className="img img--grid"
                      fixed={coverObj.cover_image.childImageSharp.fixed}
                    />
                  </div>
                </div>
                <h4 className="text-2xl text-center text-white font-semibold font-display">
                  {coverObj.id}
                </h4>

                <div className="text-white flex flex-col justify-center content-center items-center text-center mt-8 ">
                  <Link
                    className="bg-transparent hover:bg-gladior-pink    text-white  font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
                    to={coverObj.fields.slug}
                  >
                    Bekijk de Case
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default RelatedCases;
